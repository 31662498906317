<template>

	<div>
		<div class="fix">
			<div class="side-info">
				<button class="side-info-close"><i class="fa fa-times"></i></button>
				<div class="side-info-content">
					<div class="mobile-menu"></div>
				</div>
			</div>
		</div>
		<div class="offcanvas-overlay"></div>

		<main>
			<!-- === SLIDER AREA START === -->
			<div class="slider_2" style="background-image: url('/static/images/home2bg.png')">
				<div class="slider_2_shapes">
					<img class="h2_shape4" src="/static/picture/home_2_shape4.png" alt=""/>
					<img class="h2_sh_1 d-none d-sm-block" src="/static/picture/bordershape.png" alt="">
					<img class="h2_sh_2 d-none d-sm-block" src="/static/picture/home2shape2.png" alt="">
					<img class="h2_sh_3 d-none d-sm-block" src="/static/picture/h2shap3.png" alt="">
					<img class="h2_sh_4 d-none d-sm-block" src="/static/picture/h2shape2.png" alt="">
					<img class="h2_sh_5 d-none d-sm-block" src="/static/picture/home_2_shape.png" alt="">
					<img class="h2_sh_6 d-none d-sm-block" src="/static/picture/home_2_shape7.png" alt="">
					<img class="h2_sh_7 d-none d-sm-block" src="/static/picture/border2shape.png" alt="">
				</div>
				<div class="container">
					<div class="row">
						<div class="col-xl-6 col-lg-6 col-md-6">
							<div class="slider_2__content">
								<h2 class="home2_title hero-title">微行旅游生活服务新零售平台</h2>
								<p>社交+旅游新服务，助力事业风口起飞，享受高品质旅游服务</p>
								<!--								<router-link class="tp_white_border" to="contact.html">免费下载</router-link>-->
								<div>
									<div class="row g-0 pt-20">
										<div class="col-xl-4 col-md-4 col-md-4 col-sm-4" @click="downloadAndroid">
											<div class="purches_btn">
												<a href="#"><i class="fa fa-android"></i></a>
												<div class="purches_text">
													<h6>Download</h6>
													<a href="https://wx.res.weseego.com/2024/09/18/03f36f8fef4cd19018b3ab6e30a99a39.apk">安卓版</a>
												</div>
											</div>
										</div>
										<div class="col-xl-4 col-md-4 col-md-4 col-sm-4" @click="toAppStore">
											<div class="purches_btn">
												<a href="#"><i class="fa fa-apple"></i></a>
												<div class="purches_text">
													<h6>Download</h6>
													<a href="https://itunes.apple.com/cn/app/weixinglvyou/id1573548275">苹果版</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-xl-6 col-lg-6 col-md-6">
							<div class="slider_2_image">
								<div class="home2_shapes">
									<img class="h2_love" src="/static/picture/loveshape.png" alt="loveShape"/>
								</div>
								<img class="H2_iphonex d-sm-none d-md-block d-none d-sm-block" src="/static/picture/app_home.png" alt="iphoneX"/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- === SLIDER AREA END === -->

			<!-- === HOME-2 FETURES AREA START === -->
			<div class="fetures2 pt-115 pb-90">
				<div class="container">
					<div class="section_title_wrapper mb-60 text-center fetures2">
						<h2 class="section-title">直采、严选、品质、高效</h2>
						<p>-</p>
					</div>
					<div class="row">
						<template v-for="item in brand_feature_list">
							<div class="col-xl-3 col-lg-3 col-md-6">
								<div class="featurebox2 mb-30  wow  fadeInUp" data-wow-duration="2s" data-wow-delay=".2s">
									<div class="featurebox2__img">
										<img :src="item.icon" alt=""/>
									</div>
									<div class="featurebox2__content">
										<h3 class="fea-title">{{item.title}}</h3>
										<p style="min-height: 78px" v-html="item.desc"></p>
										<router-link class="fea-link" to="/product"><i class="fa fa-arrow-right"></i></router-link>
									</div>
								</div>
							</div>
						</template>
					</div>
				</div>
			</div>
			<!-- === HOME-2 FETURES AREA END === -->

			<!-- === HOME-2 APP DETAILS START === -->
			<div class="app_details_h2">
				<div class="container">
					<div class="row">
						<div class="col-xl-6 col-lg-6 col-md-12">
							<div class="app_images_h2 wow  fadeInUp" data-wow-duration="2s" data-wow-delay=".4s">
								<img class="bgphone d-none d-sm-block" src="static/picture/bgphone.png" alt="bighphone.png"/>
								<div class="shape_design_h2">
									<img class="home2FlatIphone_h2 d-none d-sm-block" src="/static/picture/home2flatiphone.png?t=1" alt="home2shape.png"/>
								</div>
							</div>
						</div>
						<div class="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center">
							<div class="details_content_h2 pl-100 wow  fadeInUp" data-wow-duration="2s" data-wow-delay=".2s">
								<h2 class="section-title mb-20">简单，无套路</h2>
								<p>旅行、机票、酒店三大核心模块，用最简约的产品描述和最直接的价格对比让你的选择无比轻松。</p>
								<p>我们拒绝一切套路，我们用心服务与新服务让更多人轻松旅行，看更多、更美、更远的世界。</p>

							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- === HOME-2 APP DETAILS END === -->

			<!-- === HOME-2 APP CHOOSE START === -->
			<div class="app_choose pt-115 mt-130 pb-160">
				<div class="container-fluidd wow  fadeInUp">
					<div class="section_title_wrapper text-center pb-55">
						<h2 class="section-title section-title-large">真实，源于生活 分享你的快乐</h2>
					</div>
					<div class="choose_active">
						<template v-for="item in share_pic_list">
							<div class="choose_item">
								<img :src="item" alt="choose_item"/>
							</div>
						</template>
					</div>
				</div>
			</div>
			<!-- === HOME-2 APP CHOOSE END === -->

			<!-- === HOME-2 USER EXPERIENCE AREA START  === -->
			<div class="experience_area">
				<div class="container wow  fadeInUp">
					<div class="section_title_wrapper text-center mb-70 mt-50">
						<h2 class="section-title" style="color: #459eff">微行，放眼看世界</h2>
						<p style="text-align: center;color: #6d7783">中国智能手机用户15.92亿，微信活跃用户高达12亿。2020年中国移动电商用户增至8.08亿人，消费者人数同比增长17.26%，并持续增长。</p>
					</div>
					<div class="row">
						<template v-for="item in count_info_list">
							<div class="col-xl-4 col-lg-4 col-md-6">
								<div style="padding: 20px; box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); text-align: center;margin-bottom: 30px">
									<div>
										<h2 style="color: #459eff">{{item.num}}</h2>
										<p>{{item.title}}</p>
									</div>
								</div>
							</div>
						</template>
					</div>
				</div>
			</div>
			<!-- === HOME-2 USER EXPERIENCE AREA END  === -->

			<div class="experience_area">
				<div class="container wow  fadeInUp" style="text-align: center; margin: 32px auto">
					<img src="/static/picture/friend_link.png" alt="link"/>
				</div>
			</div>

			<!-- === HOME-2 TESTIMONIAL AREA START  === -->
			<div class="pt-30 fix wow  fadeInUp">
				<img src="/static/picture/home2lastbg.png" alt="link"/>
			</div>
			<!-- === HOME-2 TESTIMONIAL AREA END  === -->

			<remote-script src="/static/js/main.js"></remote-script>
		</main>
	</div>
</template>

<script>
	import '@/utils/importJs.js'

	export default {
		components: {},
		data() {
			return {
				brand_feature_list: [
					{
						title: "目的地直采",
						desc: "旅游行业资源供应链顶端优势，与各航空公司、各国旅游局精密合作。",
						icon: "/static/picture/srv01.png",
					},
					{
						title: "线路自研",
						desc: "超十年经验专业产品研发团队实地考察踩线，以多维度、高标准出品微行专线。",
						icon: "/static/picture/srv02.png",
					},
					{
						title: "私人管家服务",
						desc: "一对一社交新服务，专业旅游管家团队配合快速反应客服处理旅途中的方方面面。",
						icon: "/static/picture/srv03.png",
					},
					{
						title: "微行保障",
						desc: "信息安全保障、交易安全保障、透明消费保障、订单权益保障、出行无忧保障、意外保险旅行保障。",
						icon: "/static/picture/srv04.png",
					}
				],


				share_pic_list: [
					"/static/picture/choose1.png",
					"/static/picture/choose2.png",
					"/static/picture/choose3.png",
					"/static/picture/choose4.png",
					"/static/picture/choose3.png",
					"/static/picture/choose2.png",
					"/static/picture/choose1.png",
				],

				count_info_list: [
					{
						"title": "2019国内旅游人数",
						"num": "60.06亿人次",
					},
					{
						"title": "全年实现旅游总收入",
						"num": "6.63万亿元",
					},
					{
						"title": "旅游业对GDP的综合贡献",
						"num": "10.94万亿元",
					}

				]
			}
		},
		methods: {
			toAppStore() {
				window.location.href = "https://itunes.apple.com/cn/app/weixinglvyou/id1573548275";
			},
			downloadAndroid() {
				window.location.href = "https://wx.res.weseego.com/2024/09/18/03f36f8fef4cd19018b3ab6e30a99a39.apk";
			}
		}

	}
</script>

<style scoped>

</style>